// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-site-js": () => import("../src/templates/site.js" /* webpackChunkName: "component---src-templates-site-js" */),
  "component---src-templates-link-to-js": () => import("../src/templates/linkTo.js" /* webpackChunkName: "component---src-templates-link-to-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-old-js": () => import("../src/pages/index_old.js" /* webpackChunkName: "component---src-pages-index-old-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

